// StarRating.js
import React from "react";
import { Box } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const StarRating = ({ score }) => {
  let filledStars = 0;
  if (score >= -2 && score <= -1.2) {
    filledStars = 1;
  } else if (score > -1.2 && score <= -0.4) {
    filledStars = 2;
  } else if (score > -0.4 && score <= 0.4) {
    filledStars = 3;
  } else if (score > 0.4 && score <= 1.2) {
    filledStars = 4;
  } else if (score > 1.2 && score <= 2.0) {
    filledStars = 5;
  }

  return (
    <Box display="flex" alignItems="center">
      {[...Array(5)].map((_, index) =>
        index < filledStars ? (
          <StarIcon
            key={index}
            style={{
              color: filledStars < 3 ? "red" : "gold",
              fontSize: "35px",
            }}
          />
        ) : (
          <StarBorderIcon
            key={index}
            style={{
              color: filledStars < 3 ? "grey" : "gold",
              fontSize: "35px",
            }}
          />
        )
      )}
    </Box>
  );
};

export default StarRating;
