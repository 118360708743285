// QuestionList.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  LinearProgress,
  Grid,
} from "@mui/material";
import UserInfoForm from "./UserInfoForm"; // Import the UserInfoForm
import "./Styles.css";
import "./UserInfoForm.css"; // Import the new CSS file

const QuestionList = () => {
  const location = useLocation();
  const [questions, setQuestions] = useState(location.state?.questions || []);
  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({ name: "", email: "" });
  const [showUserInfoForm, setShowUserInfoForm] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [otpTimer, setOtpTimer] = useState(null);
  const navigate = useNavigate();
  const questionRefs = useRef([]);

  const lightColors = [
    "#FFFDD0", // Cream
    "#E6F3FF", // Light Sky Blue
    "#F0FFF0", // Honeydew
    "#FFF5E6", // Linen
    "#F0FFFF", // Azure
    "#FAFAD2", // Light Goldenrod Yellow
    "#E0FFFF", // Light Cyan
    "#F5F5DC", // Beige
    "#FFF0F5", // Lavender Blush
    "#F0F8FF", // Alice Blue
    "#F8F8FF", // Ghost White
    "#FFFAF0", // Floral White
    "#F5FFFA", // Mint Cream
    "#FFEFD5", // Papaya Whip
    "#E6E6FA", // Lavender
    "#FFF5EE", // Seashell
    "#F0E68C", // Khaki
    "#FFFFE0", // Light Yellow
    "#E0EEED", // Powder Blue
    "#FDF5E6", // Old Lace
  ];

  useEffect(() => {
    if (questions.length === 0) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/questions`)
        .then((response) => {
          const fetchedQuestions = response.data.map((question) => ({
            ...question,
            backgroundColor:
              lightColors[Math.floor(Math.random() * lightColors.length)],
          }));
          setQuestions(fetchedQuestions);
        })
        .catch((error) =>
          console.error("There was an error fetching the questions!", error)
        );
    }
  }, []);

  useEffect(() => {
    if (questionRefs.current[currentPage * 5]) {
      questionRefs.current[currentPage * 5].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentPage]);

  const handleAnswerChange = (questionId, answerId) => {
    setAnswers({ ...answers, [questionId]: answerId });
    if (questionRefs.current[questionId]) {
      questionRefs.current[questionId].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const validateAnswers = () => {
    const startIndex = currentPage * 5;
    const endIndex = startIndex + 5;
    for (let i = startIndex; i < endIndex; i++) {
      const question = questions[i];
      if (!answers[question.id]) {
        return `Please answer all questions on this page.`;
      }
    }
    return "";
  };

  const handleNext = () => {
    const error = validateAnswers();
    if (error) {
      setErrorMessage(error);
    } else {
      setErrorMessage("");
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSubmit = () => {
    const error = validateAnswers();
    if (error) {
      setErrorMessage(error);
    } else {
      setErrorMessage("");
      setShowUserInfoForm(true);
    }
  };

  const handleSendOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/send-otp`,
        { email: userInfo.email }
      );
      if (response.data.success) {
        setOtpSent(true);
        setErrorMessage("");
        setOtpTimer(
          setTimeout(() => {
            setErrorMessage("OTP expired. Please request a new one.");
            setOtpSent(false);
            setOtp("");
          }, 300000)
        ); // 5 minutes
      } else {
        setErrorMessage("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setErrorMessage("Error sending OTP. Please try again.");
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify-otp`,
        { email: userInfo.email, otp }
      );
      if (response.data.success) {
        setOtpVerified(true);
        clearTimeout(otpTimer);
        await saveUserInfoAndAnswers();
        return true; // Return true on success
      } else {
        setErrorMessage("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage("Error verifying OTP. Please try again.");
    }
  };

  const saveUserInfoAndAnswers = async () => {
    setLoading(true);
    try {
      const userResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/users`,
        userInfo
      );
      const userId = userResponse.data.id;
      const userAnswers = Object.entries(answers).map(
        ([questionId, answerId]) => ({
          userId: userId,
          questionId: parseInt(questionId),
          answerId: parseInt(answerId),
        })
      );

      await axios.post(
        `${process.env.REACT_APP_API_URL}/useranswers`,
        userAnswers
      );
      // Send email with report link
      await axios.post(
        `${process.env.REACT_APP_API_URL}/useranswers/summary/${userId}/email`
      );

      localStorage.setItem("answersSubmitted", "true");

      navigate(`/report/${userId}`);
    } catch (error) {
      console.error(
        "There was an error submitting the user info and answers!",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const calculateProgress = () => {
    const totalQuestions = questions.length;
    const answeredQuestions = Object.keys(answers).length;
    return Math.round((answeredQuestions / totalQuestions) * 100);
  };

  const renderQuestions = () => {
    const startIndex = currentPage * 5;
    const endIndex = startIndex + 5;

    return questions.slice(startIndex, endIndex).map((question, index) => {
      return (
        <div
          key={question.id}
          ref={(el) => (questionRefs.current[startIndex + index] = el)}
          className={`question-container ${
            startIndex + index === currentPage * 5 ? "highlight" : ""
          }`}
          style={{ backgroundColor: question.backgroundColor }} // Apply the assigned background color
        >
          <Grid container direction="column" className="question-answers-row">
            <Grid item>
              <Typography variant="h6">{question.text}</Typography>
            </Grid>
            <Grid item>
              <RadioGroup
                className="radio-group"
                aria-label={`question-${question.id}`}
                name={`question-${question.id}`}
                value={answers[question.id] || ""}
                onChange={(e) =>
                  handleAnswerChange(question.id, e.target.value)
                }
              >
                {question.answers.map((answer) => (
                  <FormControlLabel
                    key={answer.id}
                    value={answer.id}
                    control={<Radio />}
                    label={answer.text}
                    className="answer-choice"
                  />
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
        </div>
      );
    });
  };

  if (loading) {
    return <Typography>Generating Report...</Typography>;
  }

  return (
    <Box>
      <LinearProgress
        variant="determinate"
        value={calculateProgress()}
        sx={{
          boxShadow: 1,
          p: 0.5,
          maxWidth: "100",
        }}
      />
      {showUserInfoForm ? (
        <UserInfoForm
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          handleSendOtp={handleSendOtp}
          handleVerifyOtp={handleVerifyOtp}
          otpSent={otpSent}
          otpVerified={otpVerified}
          otp={otp}
          setOtp={setOtp}
          errorMessage={errorMessage}
        />
      ) : (
        <>
          {renderQuestions()}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <Box className="navigation-buttons">
            <Button
              variant="contained"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 0}
            >
              Previous
            </Button>
            {currentPage < Math.ceil(questions.length / 5) - 1 ? (
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            ) : (
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default QuestionList;
