import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import LandingPage from "./components/LandingPage";
import QuestionList from "./components/QuestionList";
import Report from "./components/Report";
import UserInfoForm from "./components/UserInfoForm";
import ErrorBoundary from "./components/ErrorBoundary";
import "./components/Chat.css";
import LoginPage from "./components/LoginPage";
import CareerDashboard from "./components/CareerDashboard";
import ProtectedRoute from "./components/ProtectedRoute";
const App = () => {
  const handleSaveUserInfo = (userInfo) => {
    // Save user info to state or send to server
    console.log(userInfo);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route
          path="/questions"
          element={
            <ErrorBoundary>
              <QuestionList />
            </ErrorBoundary>
          }
        />
        <Route
          path="/userinfo"
          element={<UserInfoForm onSaveUserInfo={handleSaveUserInfo} />}
        />
        <Route path="/report/:userId" element={<Report />} />
        {/* <Route path="/payment/:userId" element={<PaymentPage />} /> */}
        <Route
          path="/report/:userId"
          element={
            <ErrorBoundary>
              <Report />
            </ErrorBoundary>
          }
        />
        {/* <Route path="/login" element={<LoginPage />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <CareerDashboard />
            </ProtectedRoute>
          }
        /> */}
      </Routes>
    </Router>
  );
};

export default App;
