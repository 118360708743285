// UserInfoForm.js
import React, { useState, useCallback, useEffect } from "react";
import { TextField, Button, Box, Typography, MenuItem } from "@mui/material";
import Particles from "react-tsparticles";
import ReactConfetti from "react-confetti";
import "./UserInfoForm.css";
import { loadFull } from "tsparticles";

const UserInfoForm = ({
  userInfo,
  setUserInfo,
  handleSendOtp,
  handleVerifyOtp,
  otpSent,
  otpVerified,
  otp,
  setOtp,
  errorMessage,
}) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [otpTimer, setOtpTimer] = useState(300); // 5 minutes in seconds
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    ocbcEmail: "",
    location: "",
  });

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);
  useEffect(() => {
    let interval;
    if (otpSent && !otpVerified && otpTimer > 0) {
      interval = setInterval(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [otpSent, otpVerified, otpTimer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const validateField = (field, value) => {
    let error = "";
    switch (field) {
      case "name":
        if (!value.trim()) {
          error = "Name is required";
        }
        break;
      case "email":
        if (!value.trim()) {
          error = "Email is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Please enter a valid email address";
        }
        break;
      case "ocbcEmail":
        if (!value.trim()) {
          error = "OCBC email is required";
        } else if (
          !/^[a-zA-Z0-9._%+-]+@(?:ocbc\.com|ocbc\.com\.sg|ocbc\.com\.my|ocbc\.id|ocbc\.com\.hk|ocbc\.com\.cn)$/.test(
            value
          )
        ) {
          error =
            "Please enter a valid OCBC email address (e.g., yourname@ocbc.com or yourname@ocbc.com.sg)";
        }
        break;
      case "location":
        if (!value) {
          error = "Location is required";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleFieldChange = (field, value) => {
    setUserInfo({ ...userInfo, [field]: value });
    setValidationErrors({
      ...validationErrors,
      [field]: validateField(field, value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {
      name: validateField("name", userInfo.name),
      email: validateField("email", userInfo.email),
      ocbcEmail: validateField("ocbcEmail", userInfo.ocbcEmail),
      location: validateField("location", userInfo.location),
    };
    setValidationErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return;
    }

    if (!otpSent) {
      await handleSendOtp();
    } else if (!otpVerified) {
      const success = await handleVerifyOtp();
      if (success) {
        localStorage.setItem("answersSubmitted", "true");
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
      }
    }
  };

  return (
    <Box className="user-info-form-container">
      {showConfetti && (
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            fullScreen: {
              enable: true,
              zIndex: 1,
            },
            particles: {
              number: {
                value: 0,
              },
              color: {
                value: ["#00FFFC", "#FC00FF", "#fffc00"],
              },
              shape: {
                type: ["circle", "square"],
                options: {},
              },
              opacity: {
                value: 1,
                animation: {
                  enable: true,
                  minimumValue: 0,
                  speed: 2,
                  startValue: "max",
                  destroy: "min",
                },
              },
              size: {
                value: 4,
                random: {
                  enable: true,
                  minimumValue: 2,
                },
              },
              links: {
                enable: false,
              },
              life: {
                duration: {
                  sync: true,
                  value: 5,
                },
                count: 1,
              },
              move: {
                enable: true,
                gravity: {
                  enable: true,
                  acceleration: 10,
                },
                speed: {
                  min: 10,
                  max: 20,
                },
                decay: 0.1,
                direction: "none",
                straight: false,
                outModes: {
                  default: "destroy",
                  top: "none",
                },
              },
              rotate: {
                value: {
                  min: 0,
                  max: 360,
                },
                direction: "random",
                animation: {
                  enable: true,
                  speed: 60,
                },
              },
              tilt: {
                direction: "random",
                enable: true,
                value: {
                  min: 0,
                  max: 360,
                },
                animation: {
                  enable: true,
                  speed: 60,
                },
              },
              roll: {
                darken: {
                  enable: true,
                  value: 25,
                },
                enable: true,
                speed: {
                  min: 15,
                  max: 25,
                },
              },
              wobble: {
                distance: 30,
                enable: true,
                speed: {
                  min: -15,
                  max: 15,
                },
              },
            },
            emitters: {
              life: {
                count: 0,
                duration: 0.1,
                delay: 0.4,
              },
              rate: {
                delay: 0.1,
                quantity: 150,
              },
              size: {
                width: 0,
                height: 0,
              },
            },
          }}
        />
      )}
      <form className="user-info-form" onSubmit={handleSubmit}>
        <Typography variant="h5">Just a moment...</Typography>
        <Typography variant="subtitle1">
          We'll save your results so you can easily access them again.
        </Typography>
        <TextField
          label="Name"
          variant="outlined"
          value={userInfo.name}
          onChange={(e) => handleFieldChange("name", e.target.value)}
          required
          fullWidth
          margin="normal"
          disabled={otpSent}
          error={!!validationErrors.name}
          helperText={validationErrors.name}
        />
        <TextField
          label="Your personal email to receive results"
          variant="outlined"
          value={userInfo.email}
          onChange={(e) => handleFieldChange("email", e.target.value)}
          onBlur={(e) => handleFieldChange("email", e.target.value.trim())}
          required
          fullWidth
          margin="normal"
          disabled={otpSent}
          error={!!validationErrors.email}
          helperText={validationErrors.email}
        />
        <TextField
          label="Your Location"
          select
          variant="outlined"
          value={userInfo.location || ""}
          onChange={(e) => handleFieldChange("location", e.target.value)}
          required
          fullWidth
          margin="normal"
          disabled={otpSent}
        >
          {["Tower 1", "Tower 2", "Others"].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Your OCBC email for our reference"
          variant="outlined"
          value={userInfo.ocbcEmail}
          onChange={(e) => handleFieldChange("ocbcEmail", e.target.value)}
          onBlur={(e) => handleFieldChange("ocbcEmail", e.target.value.trim())}
          required
          fullWidth
          margin="normal"
          disabled={otpSent}
          error={!!validationErrors.ocbcEmail}
          helperText={validationErrors.ocbcEmail}
        />
        <TextField
          label="Your most pressing Career Concern"
          variant="outlined"
          value={userInfo.careerConcern}
          onChange={(e) =>
            setUserInfo({ ...userInfo, careerConcern: e.target.value })
          }
          required
          fullWidth
          margin="normal"
          multiline
          rows={3}
          disabled={otpSent}
        />
        {otpSent && !otpVerified && (
          <>
            <TextField
              label="OTP sent to your personal email"
              variant="outlined"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <Typography variant="body2" color="textSecondary">
              OTP valid for: {formatTime(otpTimer)}
            </Typography>
          </>
        )}
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={otpSent && otpTimer === 0}
        >
          {otpSent ? "Verify Email OTP" : "Verify & View Results"}
        </Button>
      </form>
    </Box>
  );
};

export default UserInfoForm;
