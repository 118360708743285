// EmailVerificationForm.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";

const EmailVerificationForm = ({ onVerificationSuccess }) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const answersSubmitted = localStorage.getItem("answersSubmitted");
    if (answersSubmitted === "true") {
      const storedUserId = localStorage.getItem("userId");
      if (storedUserId) {
        onVerificationSuccess(storedUserId);
      }
    }
  }, [onVerificationSuccess]);

  const handleSendOtp = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/send-otp`,
        { email }
      );
      if (response.data.success) {
        setOtpSent(true);
        setErrorMessage("OTP sent successfully. Please check your email.");
      } else {
        setErrorMessage("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setErrorMessage("Error sending OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify-otp`,
        { email, otp }
      );
      if (response.data.success) {
        localStorage.setItem("answersSubmitted", "true");
        setErrorMessage("OTP verified successfully. Redirecting...");

        // Fetch user information to get the userId
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/email/${email}`
        );
        if (userResponse.data && userResponse.data.id) {
          localStorage.setItem("userId", userResponse.data.id);
          onVerificationSuccess(userResponse.data.id);
        } else {
          setErrorMessage("User not found. Please try again.");
        }
      } else {
        setErrorMessage("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage("Error verifying OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  if (!showForm) {
    return null; // Don't render anything if the form shouldn't be shown
  }

  return (
    <Box className="user-info-form-container">
      <form className="user-info-form" onSubmit={(e) => e.preventDefault()}>
        <Typography variant="h5">Email Verification</Typography>
        <Typography variant="subtitle1">
          Please enter your email to access the report.
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          value={email.trim()}
          onChange={(e) => {
            const trimmedEmail = e.target.value.trim();
            setEmail(trimmedEmail);
            // Basic email format validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(trimmedEmail)) {
              setErrorMessage("Please enter a valid email address.");
            } else {
              setErrorMessage("");
            }
          }}
          //   error={!!errorMessage}
          //   helperText={errorMessage}
          required
          fullWidth
          margin="normal"
          disabled={otpSent || isLoading}
        />
        {otpSent && (
          <TextField
            label="OTP"
            variant="outlined"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
            fullWidth
            margin="normal"
            disabled={isLoading}
          />
        )}
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={otpSent ? handleVerifyOtp : handleSendOtp}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : otpSent ? (
            "Verify OTP"
          ) : (
            "Send OTP"
          )}
        </Button>
      </form>
    </Box>
  );
};

export default EmailVerificationForm;
