import React from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";
import "./SummaryPage.css";
const titleIconMap = {
  careerWellnessScore: "/images/SummaryIcons/01 - Career Wellness.png",
  careerHappinessScore: "/images/SummaryIcons/02 Career Happiness Score.png",
  careerStrategyScore: "/images/SummaryIcons/03 Career Strategy Score.png",
  careerAgilityScore: "/images/SummaryIcons/04 Career Agility Score.png",
  skillsClarityScore: "/images/SummaryIcons/05 Skills Clarity Score.png",
  workEnvironmentScore: "/images/SummaryIcons/06 Work Environment Score.png",
  workContentSatisfactionScore:
    "/images/SummaryIcons/07 Work Content Satisfaction Score.png",
  careerDisruptionReadinessScore:
    "/images/SummaryIcons/08 Career Disruption.png",
  networkingProficiencyScore:
    "/images/SummaryIcons/09 Networking Proficiency Score.png",
  workLifeBalanceScore: "/images/SummaryIcons/10 Work Life Balance Score.png",
  salarySatisfactionScore:
    "/images/SummaryIcons/11 Salary Satisfaction Score.png",
  satisfactionWithCurrentWorkloadScore:
    "/images/SummaryIcons/12 Satisfaction With Current Workload.png",
  jobSecurityScore: "/images/SummaryIcons/13 Job Security.png",
};

const PDFSummaryPage = ({ summary }) => {
  const renderScoreBar = (score, key, index) => {
    const scorePercentage = (score / 5) * 100;
    const scoreColor = score < 2.5 ? "#ff0000" : "#1976d2";

    return (
      <Box mb={1}>
        <Box display="flex" alignItems="center">
          <img src={titleIconMap[key]} alt={`${key}`} className="title-icon" />
          <Box flexGrow={1} ml={2}>
            <Typography
              variant="subtitle1"
              style={{ minWidth: "200px", fontSize: "12px" }}
            >
              {index + 1}. {getScoreTitle(key)}
            </Typography>
            <Typography
              variant="body2"
              align="right"
              style={{ fontSize: "12px" }}
            >
              {isNaN(score) ? "N/A" : score.toFixed(1)}/5.0
            </Typography>
            <Box flexGrow={1}>
              <div
                style={{
                  backgroundColor: "#e0e0e0",
                  height: "5px",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    width: `${scorePercentage}%`,
                    backgroundColor: scoreColor,
                    height: "100%",
                    borderRadius: "5px",
                  }}
                />
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const getScoreTitle = (key) => {
    const titles = {
      careerWellnessScore: "Career Wellness Score",
      careerHappinessScore: "Career Happiness Score",
      careerStrategyScore: "Career Strategy Score",
      careerAgilityScore: "Career Agility Score",
      skillsClarityScore: "Skills Clarity Score",
      workEnvironmentScore: "Work Environment Score",
      workContentSatisfactionScore: "Work Content Satisfaction Score",
      careerDisruptionReadinessScore: "Career Disruption Readiness Score",
      networkingProficiencyScore: "Networking Proficiency Score",
      workLifeBalanceScore: "Work-Life Balance Score",
      salarySatisfactionScore: "Salary Satisfaction Score",
      satisfactionWithCurrentWorkloadScore:
        "Satisfaction with Current Workload",
      jobSecurityScore: "Job Security Score",
    };
    return titles[key] || key;
  };

  const renderCareerStage = (stage) => {
    let text = "";
    if (stage === 2) {
      text =
        "Accelerator: Your immediate desire is to progress your career to the next step in terms of skills, responsibilities and/or salary.";
    } else if (stage === 1) {
      text =
        "Urgent Role-Seeker: You are searching for the next role urgently and would like to land a new one soon.";
    } else if (stage === 0) {
      text =
        "Crossroader: You are at a point in your career where you are trying to figure out what your next move should be.";
    } else {
      text = "N/A";
    }

    return (
      <Box mb={1} display="flex" alignItems="center" style={{ padding: "4px" }}>
        <img
          src="/images/SummaryIcons/14 Career Stage.png"
          alt="Career Stage"
          className="title-icon"
        />
        <Box ml={2}>
          <Typography variant="subtitle1" align="left">
            Career Stage
          </Typography>
          <Typography variant="subtitle2" align="left">
            {text}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderCareerVelocity = (stage) => {
    let text = "";
    if (stage === 2) {
      text =
        "You are a Career Accelerator and plan to progress your career in the near future.";
    } else if (stage === 1) {
      text =
        "You intend to Hover and stay put at the moment or make a lateral move.";
    } else if (stage === 0) {
      text =
        "You plan to Downshift and take things a bit easier from here onwards to slow down your career.";
    } else {
      text = "N/A";
    }
    return (
      <Box mb={1} display="flex" alignItems="center" style={{ padding: "4px" }}>
        <img
          src="/images/SummaryIcons/15 Career Velocity.png"
          alt="Career Stage"
          className="title-icon"
        />
        <Box ml={2}>
          <Typography variant="subtitle1" align="left">
            Career Velocity
          </Typography>
          <Typography variant="subtitle2" align="left">
            {text}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderCareerPivot = (stage) => {
    let text = "";
    if (stage === 1) {
      text =
        "You are considering remaining in the same industry and in the same role.";
    } else if (stage === 2) {
      text =
        "You are considering staying in the same role but switching to another industry.";
    } else if (stage === 3) {
      text =
        "You are considering staying in your current industry but switching roles.";
    } else {
      text =
        "You are considering switching into an entirely new area that is different from your current role or industry.";
    }
    return (
      <Box mb={1} display="flex" alignItems="center" style={{ padding: "4px" }}>
        <img
          src="/images/SummaryIcons/16 Crossover Quadrant Career Pivot.png"
          alt="Career Pivot"
          className="title-icon"
        />
        <Box ml={2}>
          <Typography variant="subtitle1" align="left">
            Career Pivot
          </Typography>
          <Typography variant="subtitle2" align="left">
            {text}
          </Typography>
        </Box>
      </Box>
    );
  };

  const firstColumnItems = [
    "careerWellnessScore",
    "careerHappinessScore",
    "careerStrategyScore",
    "careerAgilityScore",
    "skillsClarityScore",
    "workEnvironmentScore",
    "workContentSatisfactionScore",
  ];

  const secondColumnItems = [
    "careerDisruptionReadinessScore",
    "networkingProficiencyScore",
    "workLifeBalanceScore",
    "salarySatisfactionScore",
    "satisfactionWithCurrentWorkloadScore",
    "jobSecurityScore",
  ];

  return (
    <Box
      id="pdf-summary-content"
      className="pdf-content"
      style={{ width: "900px", padding: "20px", fontSize: "14px" }}
    >
      <Grid container spacing={2} gutterBottom>
        <Grid item xs={2}>
          <img
            src="/images/Logo/cai logo.jpg"
            alt="Company Logo"
            style={{
              maxWidth: "100px",
              marginRight: "10px",
            }}
            align="left"
          />
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography
              variant="h5"
              gutterBottom
              align="left"
              fontWeight="bold"
              style={{ fontSize: "16px" }}
            >
              Hi {summary.userName}, Here is your Personalised Career Wellness
              Report Summary
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              align="left"
              style={{ fontSize: "10px" }}
            >
              As you know, our careers are very important to us and having a
              good and well-balanced career is essential for our personal
              well-being. Here are the details of your Career Wellness
              Assessment:
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              align="left"
              fontWeight="bold"
              style={{ fontSize: "12px" }}
            >
              Email: {summary.userEmail}
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              align="left"
              fontWeight="bold"
              style={{ fontSize: "12px" }}
            >
              {`Report Date: ${new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} style={{ paddingBottom: "6px" }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            align="right"
            fontWeight="bold"
            style={{ fontSize: "10px" }}
          >
            Your Career Avatar is {summary.careerAvatar}
          </Typography>
          {summary.careerAvatar && (
            <img
              src={`/images/Career Avatars/Square - ${summary.careerAvatar}.jpg`}
              alt={`${summary.careerAvatar} Avatar`}
              style={{ maxWidth: "60px", float: "right" }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} gutterBottom>
        <Grid item xs={4}>
          <Box
            style={{
              border: "1px solid black",
              padding: "2px",
              height: "100%",
              backgroundColor: "#f0f0f0",
            }}
          >
            {renderCareerStage(summary.careerStage)}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            style={{
              border: "1px solid black",
              padding: "2px",
              height: "100%",
              backgroundColor: "#f0f0f0",
            }}
          >
            {renderCareerVelocity(summary.careerVelocity)}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            style={{
              border: "1px solid black",
              padding: "2px",
              height: "100%",
              backgroundColor: "#f0f0f0",
            }}
          >
            {renderCareerPivot(summary.careerPivot)}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} gutterBottom>
        <Grid item xs={12} marginTop={2}>
          <Typography variant="h6" style={{ fontSize: "16px" }}>
            Your Internal Career Scores
          </Typography>
          <Grid container spacing={2}>
            {[
              "careerWellnessScore",
              "careerHappinessScore",
              "careerStrategyScore",
              "careerAgilityScore",
              "skillsClarityScore",
            ].map(
              (key, index) =>
                summary[key] !== undefined && (
                  <Grid item xs={6} key={key}>
                    <Box
                      mb={1}
                      style={{ border: "1px solid black", padding: "4px" }}
                    >
                      {renderScoreBar(summary[key], key, index)}
                    </Box>
                  </Grid>
                )
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontSize: "16px" }} gutterBottom>
            Your Key Career Satisfiers
          </Typography>
          <Grid container spacing={2}>
            {[
              "salarySatisfactionScore",
              "workEnvironmentScore",
              "workContentSatisfactionScore",
              "workLifeBalanceScore",
              "satisfactionWithCurrentWorkloadScore",
              "jobSecurityScore",
            ].map(
              (key, index) =>
                summary[key] !== undefined && (
                  <Grid item xs={6} key={key}>
                    <Box
                      mb={1}
                      style={{ border: "1px solid black", padding: "4px" }}
                    >
                      {renderScoreBar(summary[key], key, index)}
                    </Box>
                  </Grid>
                )
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontSize: "16px" }} gutterBottom>
            Your External Career Scores
          </Typography>
          <Grid container spacing={2}>
            {[
              "networkingProficiencyScore",
              "careerDisruptionReadinessScore",
            ].map(
              (key, index) =>
                summary[key] !== undefined && (
                  <Grid item xs={6} key={key}>
                    <Box
                      mb={1}
                      style={{ border: "1px solid black", padding: "4px" }}
                    >
                      {renderScoreBar(summary[key], key, index)}
                    </Box>
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            style={{
              border: "1px solid black",
              backgroundColor: "#f0f0f0",
              marginTop: "6px",
            }}
          >
            <img
              src="/images/SummaryIcons/17 Disatisfiers Header.png"
              alt="Your potential disatisfiers are"
              className="title-icon"
              style={{
                padding: "4px",
                marginRight: "8px",
              }}
            />
            <Box ml={2}>
              <Typography
                variant="subtitle1"
                gutterBottom
                align="left"
                color="error"
                fontWeight="bold"
                style={{ fontSize: "12px" }}
              >
                Your Career Disatisfiers or Areas for Further Exploration:
              </Typography>
              {summary.topNegativeWorkSatisfiers.map((item, index) => {
                let displayTitle = item.title;
                switch (item.title) {
                  case "Job Security":
                    displayTitle =
                      "It seems you may be feeling a bit insecure about your job stability.";
                    break;
                  case "Work":
                    displayTitle =
                      "It seems the nature of your current work could be a possible source of dissatisfaction for you.";
                    break;
                  case "Team":
                    displayTitle =
                      "It seems that you might be experiencing some unhappiness with the team you're working with.";
                    break;
                  case "Personal Happiness":
                    displayTitle =
                      "Your results suggest that you might not be too happy in your current job.";
                    break;
                  case "Salary":
                    displayTitle =
                      "Your results indicate you could be dissatisfied with your current salary.";
                    break;
                  default:
                    break;
                }
                return (
                  <Typography
                    variant="body2"
                    align="left"
                    key={index}
                    style={{ fontSize: "12px" }}
                  >
                    {index + 1}. {displayTitle}
                  </Typography>
                );
              })}
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <Box mt={2}>
        <Divider />

        <p>
          © Copyright, Career Agility International Pte Ltd, 2017-
          {new Date().getFullYear()}, All rights reserved. No part of this
          report is to be copied or duplicated without the express written
          permission of the copyright holder.
        </p>
      </Box>
    </Box>
  );
};

export default PDFSummaryPage;
