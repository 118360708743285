import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  CircularProgress,
} from "@mui/material";
import "./LandingPage.css";

localStorage.setItem("answersSubmitted", "false");

const lightColors = [
  "#FFFDD0", // Cream
  "#E6F3FF", // Light Sky Blue
  "#F0FFF0", // Honeydew
  "#FFF5E6", // Linen
  "#F0FFFF", // Azure
  "#FAFAD2", // Light Goldenrod Yellow
  "#E0FFFF", // Light Cyan
  "#F5F5DC", // Beige
  "#FFF0F5", // Lavender Blush
  "#F0F8FF", // Alice Blue
  "#F8F8FF", // Ghost White
  "#FFFAF0", // Floral White
  "#F5FFFA", // Mint Cream
  "#FFEFD5", // Papaya Whip
  "#E6E6FA", // Lavender
  "#FFF5EE", // Seashell
  "#F0E68C", // Khaki
  "#FFFFE0", // Light Yellow
  "#E0EEED", // Powder Blue
  "#FDF5E6", // Old Lace
];

const LandingPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/questions`
        );
        const fetchedQuestions = response.data.map((question) => ({
          ...question,
          backgroundColor:
            lightColors[Math.floor(Math.random() * lightColors.length)],
        }));
        setQuestions(fetchedQuestions);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, []);

  const handleStart = () => {
    setIsLoading(true);
    navigate("/questions", { state: { questions } });
  };

  return (
    <>
      <ul class="background">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <Box
        className="landing-container"
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Card className="landing-card">
          <Grid container>
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                src="/images/Landing Page/CAROL TITLE PIC.jpeg"
                alt="Career Path"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CardContent>
                <Typography variant="h4" component="h1" gutterBottom>
                  Hi! I'm CoachCarol™
                </Typography>
                <Typography variant="h5" component="h1" gutterBottom>
                  I will be asking you 30 Questions about your Career Health. It
                  takes less than 5 minutes to complete.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                  I will then analyze your current situation and offer
                  personalised recommendations based on your results that can
                  improve your Career.
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  gutterBottom
                ></Typography>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleStart}
                  className="start-button"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Let's Begin!"}
                </Button>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate("/login")}
                  className="login-button"
                  style={{ marginLeft: "10px" }}
                >
                  Login
                </Button> */}
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default LandingPage;
